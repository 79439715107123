// lib
import React from 'react';
import { Box, Typography, Grid, SxProps } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import Image from 'next/image';
// src
import { Button, LibButtonProps } from '../../components/Button';
// styles&utils
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';

export interface LibHelpBoxImageProps {
  richTextLeft: string;
  btnPrimary?: {
    label: string;
    link: string;
    colorProp?: LibButtonProps['variant'];
  };
  btnSecondary?: {
    label: string;
    link: string;
    colorProp?: LibButtonProps['variant'];
  };
  img: string;
  altImg: string;
  customContainerStyles?: Record<string, SxProps>;
  primaryNotInteractiveOnBiggerScreen?: boolean;
}

function LibHelpBoxImage({
  richTextLeft,
  btnPrimary,
  btnSecondary,
  img,
  altImg
}: LibHelpBoxImageProps) {
  const overrides = {
    ...markdownOverrides,
    h2: {
      component: Typography,
      props: { variant: 'h2', sx: styles.mainHeading }
    },
    h3: {
      component: Typography,
      props: { variant: 'h3', sx: styles.subHeading }
    },
    h4: {
      component: Typography,
      props: { variant: 'h4', sx: styles.subHeading }
    },
    p: {
      component: Typography,
      props: { variant: 'body1', sx: styles.paragraph }
    }
  };

  return (
    <Box component="section" sx={styles.mainContainer}>
      <Box sx={styles.imageBox}>
        <Image
          alt={altImg}
          width={0}
          height={0}
          sizes="100vw"
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          src={img}
          loading="lazy"
          unoptimized
        />
      </Box>
      <Box sx={styles.textBox}>
        <Grid container columnSpacing={10}>
          <Grid item xs={12} lg={7}>
            {richTextLeft ? (
              <Markdown options={{ overrides }}>{richTextLeft}</Markdown>
            ) : null}
            {btnPrimary || btnSecondary ? (
              <Box sx={styles.btnContainerDoubleColTop}>
                {btnPrimary ? (
                  <Button
                    href={btnPrimary.link}
                    variant={btnPrimary?.colorProp || 'primary'}
                  >
                    {btnPrimary.label}
                  </Button>
                ) : null}
                {btnSecondary ? (
                  <Button
                    href={btnSecondary.link}
                    variant={btnSecondary?.colorProp || 'secondary'}
                  >
                    {btnSecondary.label}
                  </Button>
                ) : null}
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export const HelpBoxImage = React.memo(LibHelpBoxImage);

export default HelpBoxImage;

import { SxProps } from '@mui/material';
import { lightTheme, globalLayout } from '../../theme/index';

const { palette, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  mainContainer: {
    position: 'relative',
    height: {
      xs: '60vh',
      md: '50vh'
    }
  },
  imageBox: {
    width: '100%',
    height: '100%',
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    }
  },
  textBox: {
    ...globalLayout,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  mainHeading: {
    color: palette.secondary.main,
    my: spacing(1)
  },
  subHeading: {
    lineHeight: 1.2,
    color: palette.grey['50'],
    my: spacing(0.5)
  },
  paragraph: {
    color: palette.primary['50'],
    marginBottom: spacing(1)
  },

  btnContainerDoubleColTop: {
    display: 'flex',
    marginY: spacing(2),
    gap: spacing(0.5)
  }
};

export default styles;
